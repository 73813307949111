import Http from "../util/Http";

class UserApi {

    static async duplCheck(userId) {
        const res = await Http.post('/user/idDuplicate', { userId });
        return res || null;
    }

    static async emailCheck(userEmail) {
        const res = await Http.post('/user/validEmail', { userEmail });
        return res || null;
    }

    static async join(user) {
        const res = await Http.post('/user/join', user)
        return res || null;
    }

    static async getUsers() {
        const res = await Http.get('/user/list')
        return res || null;
    }

    static async updateUserInfo(data) {
        const res = await Http.post('/user/update', data);
        return res || null;
    }
}

export default UserApi;