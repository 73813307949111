import './footer.css';

const Footer = props => {
    return (
        <footer>
            <div class="footer-inner">
                <div class="corp-info">
                    <div class="corp-number-wrap">
                        <div class="number-area">
                            <div class="bottom-call-icon">
                                <img src="/static/img/icon/call-icon.png" />
                            </div>
                            <p class="corp-number">031-917-7114</p>
                        </div>
                    </div>
                    <div class="number-desc">
                        <p>
                            영업 시간 11AM - 5PM<br /> 
                            주말, 공휴일은 휴무입니다.
                            전화연결이 안 될 경우, 
                            게시판에 문의 남겨주시면 빠른 처리 해드리겠습니다.
                        </p>    
                    </div>
                    </div>
                    <div class="boundary-line"></div>
                    {/* <div class="logo-item">
                        <img class="logo-img-bottom" src="/static/img/about/smblogo.png" />
                    </div> */}
                    <p>
                        (주) 에스엠비 | 대표 : 방진성 | 이메일주소 : <a>ktt080@naver.com</a><br />
                        일산동구 정발산로 19 지평프라자 1001호 1002호<br />
                        사업자번호 : 832-81-01157 | 개인정보 책임자 : 방진성 <br />
                        <a>개인정보처리방침</a> | <a>이용약관</a>
                    </p>
                </div>
        </footer>
    )
}

export default Footer;