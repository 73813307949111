import './register.css';

import UserApi from '../../../api/UserApi';

import { useState } from "react";
import { Button } from "semantic-ui-react";

const FindInfo = () => {

    const [target, setTarget] = useState('id');
    const [userNm, setUserNm] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [loginId, setLoginId] = useState('');

    const handleUserNmInput = e => setUserNm(e.target.value);
    const handlePhoneInput = e => setUserPhone(e.target.value);
    const handleIdInput = e => setLoginId(e.target.value);

    
    const handleFindClick = async () => {
        // const res = await Http.post('/user/find', {target, userNm, userPhone});
        // if (res.code === 'fail') return alert('아이디/비밀번호 찾기를 실패하였습니다. 잠시후 다시 시도해주세요.')
        // if (type === 'id') return alert(`아이디는 '${res.userId}' 입니다.`);
        // return alert('임시 비밀번호가 메일로 발송되었습니다.');
    }

    const handleKeyUpInput = e => {
        e.preventDefault();
        if (e.which !== 13) return;
        handleFindClick();
    }

    return (
        <div className="contents">
            <div class="login-container">
                <div class="login-box">
                    <h2>아이디 / 비밀번호 찾기</h2>
                    <div class="input-group">
                        <Button primary={target === 'id'} onClick={() => setTarget('id')}>아이디</Button>
                        <Button primary={target === 'pw'} onClick={() => setTarget('pw')}>비밀번호</Button>
                    </div>
                    <div class="input-group">
                        {target === 'id' && <input target="text" placeholder="이름" value={userNm} onChange={handleUserNmInput} />}
                        {target === 'pw' && <input target="text" placeholder="아이디" value={loginId} onChange={handleIdInput} />}
                    </div>
                    <div class="input-group">
                        <input target="password" placeholder="이메일" value={userPhone} onChange={handlePhoneInput} onKeyUp={handleKeyUpInput}/>
                    </div>
                    <div class="button-group">
                        <button onClick={handleFindClick}>찾기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindInfo;