import './register.css';

import { useState, useEffect } from 'react';
import UserApi from '../../../api/UserApi';
import { Button } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';

const MyInfo = props => {

    const navigate = useNavigate();

    const [pwChgOn, setPwChgOn] = useState(false);
    const [emailChgOn, setEmailChgOn] = useState(false);

    const [user, setUser ] = useState({
        userPw : '',
        userConfirmPw : '',
    });

    const handleUserData = e => {
        const { name, value } = e.target;
        const newUser = {...user};
        newUser[name] = value;
        setUser(newUser);
    }

    const handleCheckEmailClick = e => {
        UserApi.emailCheck(user.userEmail).then(res => {
            if (res.code === 'success') return;
            alert('이메일 전송에 실패하였습니다. 잠시후 다시 시도해주세요.')
        });
        alert('메일 전송에 1~3분 정도 소요됩니다.');
    }

    const beforeRegisterCheck = () => {
        console.log(
            !user.userNm, 
            (pwChgOn && (!user.userPw || !user.userConfirmPw)), 
            (emailChgOn && (!user.userEmail || !user.userEmailValid)),
            !user.userPhone,
            user.userPw !== user.userConfirmPw
        );

        if (!user.userNm
            || (pwChgOn && (!user.userPw || !user.userConfirmPw)) 
            || (emailChgOn && (!user.userEmail || !user.userEmailValid)) 
            || !user.userPhone) return false;
        if (user.userPw !== user.userConfirmPw) return false;
        return true;
    }

    const handleRegisterClick = async e => {
        e.preventDefault();
        const res = await UserApi.updateUserInfo(user);
        if (res && res.complete === true) {
            alert('회원 정보 변경이 완료되었습니다.');
            sessionStorage.setItem('userInfo', JSON.stringify(user));
            setPwChgOn(false);
            setEmailChgOn(false);
        } else {
            alert('회원 정보 변경을 실패하였습니다.');
        }
    }

    useEffect(() => {
        const loginUser = JSON.parse(sessionStorage.getItem('userInfo'));
        setUser({...loginUser, userConfirmPw: loginUser.userPw});
    }, [])

    return (
        <div className="form-container">
            <form>
                <div className="form-group" target="pw">
                    <label for="userId" className="my-info-lb"><span className="required">*</span>아이디 :</label>
                    <div className="input-wrapper">
                        <input type="text" name="userId" className="input-field" value={user.userId} disabled />
                    </div>
                </div>
                <div className="form-group password-group">
                    <label for="userPw" className="my-info-lb"><span className="required">*</span>비밀번호 :</label>
                    
                    { pwChgOn ? <>
                        <Button onClick={() => setPwChgOn(false)}>취소</Button>
                        <div className="input-wrapper">
                            <input type="password" name="userPw" className="input-field" placeholder="비밀번호" required value={user.userPw} onChange={handleUserData} />
                        </div>
                        <div className="input-wrapper">
                            <input type="password" name="userConfirmPw" className="input-field" placeholder="비밀번호 확인" required value={user.userConfirmPw} onChange={handleUserData} />
                        </div>
                        <div className="cfm-ps-info">
                            {user.userPw.length < 8 && <span className="warning">비밀번호는 8자리 이상 입력하세요.</span>}
                            {(user.userPw.length < 8 && user.userPw !== user.userConfirmPw) && <br/>}
                            {user.userPw !== user.userConfirmPw && <span className="warning">비밀번호가 일치하지 않습니다.</span>}
                        </div>
                    </> :
                        <Button onClick={() => setPwChgOn(true)}>재설정</Button>
                    }
                </div>
                <div className="form-group" target="id">
                    <label for="userNm" className="my-info-lb"><span className="required">*</span>이름 :</label>
                    <div className="input-wrapper">
                        <input type="text" name="userNm" id="userNm" className="input-field" value={user.userNm} disabled />
                    </div>
                </div>
                <div className="form-group password-group" target="pw">
                    <label for="userEmail" className="my-info-lb"><span className="required">*</span>이메일 :</label>
                    { emailChgOn ? <>
                        <Button onClick={() => setEmailChgOn(false)}>취소</Button>
                        <div className="input-wrapper">
                            <input type="email" name="userEmail" className="input-field" placeholder="korea@naver.com" value={user.userEmail} onChange={handleUserData} />
                            <Button primary onClick={handleCheckEmailClick}>인증하기</Button>
                        </div>
                        <div className="input-wrapper">
                            <input type="text" name="userEmailValid" className="input-field" placeholder="인증번호입력" value={user.userEmailValid} onChange={handleUserData} />
                        </div>
                    </> :
                        <Button onClick={() => setEmailChgOn(true)}>재설정</Button>
                    }
                </div>
                <div className="form-group" target="id">
                    <label for="userPhone" className="my-info-lb"><span className="required">*</span>휴대전화번호 :</label>
                    <div className="input-wrapper">
                        <input type="tel" name="userPhone" id="userPhone" className="input-field" placeholder="01012345678 (-제외)" required value={user.userPhone} onChange={handleUserData} />
                    </div>
                    {/* <div className="ph-info">
                        <span className="warning">입력하신 정보는 전화번호 양식이 아닙니다.</span>
                    </div> */}
                </div>
            </form>
            <div>
                { beforeRegisterCheck() 
                    ? <Button onClick={handleRegisterClick} fluid primary >변경하기</Button>
                    : <Button fluid disabled>변경하기</Button>
                }
            </div>
        </div>
    )
}

export default MyInfo;