import React from 'react';
import { HashRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import './App.css';
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import Content from './component/content/Content';
import Banner from './component/banner/Banner';
import Shopping from './component/container/shopping/Shopping';
import Login from './component/container/login/Login';
import Home from './component/container/home/Home';
import Register from './component/container/user/Register';
import Place from './component/container/place/Place';
import FindInfo from './component/container/user/FindInfo';
import MyInfo from './component/container/user/MyInfo';
import Admin from './component/container/admin/Admin';

function App() {
  return (
    <div className="App">
        <Router>
          <Header />
          <Content>
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/shopping" Component={Shopping} />
              <Route path="/place" Component={Place} />
              <Route path="/login" Component={Login} />
              <Route path="/register" Component={Register} />
              <Route path="/findInfo" Component={FindInfo} />
              <Route path="/myInfo" Component={MyInfo} />
              <Route path="/admin" Component={Admin} />
            </Routes>
          </Content>
          <Footer />
        </Router>
    </div>
  );
}

export default App;

