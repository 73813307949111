import Http from '../util/Http'

class SearchApi {
    static async searchShopping(keyword, prodNm) {
        const res = await Http.get(`/search/shopping?keyword=${keyword}&prodNm=${prodNm}`);
        return res.data || null;
    }
    static async searchPlace(keyword, prodNm) {
        const res = await Http.get(`/search/place?keyword=${keyword}&prodNm=${prodNm}`);
        return res.data || null;
    }


}

export default SearchApi