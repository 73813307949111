import { useState, useEffect } from 'react';
import { MenuMenu, MenuItem, Menu, Segment } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom';

const defaultCheckIsLogin = () => {
    if (sessionStorage.getItem('userInfo')) return true;
    return false;
}

const Header = props => {
    const navigate = useNavigate();
    const location = useLocation();
 
    const [isLogin, setIsLogin] = useState(defaultCheckIsLogin());
    const [isAdmin, setIsAdmin] = useState(false);

    const handleMenuClick  = (e, { linkurl }) => {
        // if(linkurl === '/place') return alert('오픈 준비중입니다.')
        navigate(linkurl);
    }

    const handleLogoutClick = () => {
        sessionStorage.removeItem('userInfo');
        setIsLogin(false);
        // Feature 로그인 기능 삭제
        // navigate('/login');
    }

    useEffect(() => {
        const handler = e => {
            if (e.detail.userInfo) {
                setIsLogin(true);
                setIsAdmin(e.detail.userInfo.userType === 'admin');
                sessionStorage.setItem('userInfo', JSON.stringify(e.detail.userInfo));
            }else {
                handleLogoutClick();
            }
        }
        document.addEventListener('userInfo', handler);
        handler({detail : {userInfo : JSON.parse(sessionStorage.getItem('userInfo'))}});
        return () => {
            document.removeEventListener('userInfo', handler);
        }
    }, [])


    return (
        <Menu pointing secondary size='huge' className='header'>
            <MenuItem position='left' linkurl='/' onClick={handleMenuClick}>
                <div className='logo-container' style={{backgroundImage: "url('/static/img/about/smblogo.png')"}}>
                </div>
            </MenuItem>
            <MenuMenu position='middle'>
                <MenuItem
                    className='nav-item'
                    name='회사 소개'
                    linkurl='/'
                    active={location.pathname === '/'}
                    onClick={handleMenuClick}
                />
                <MenuItem
                    className='nav-item'
                    name='N 쇼핑'
                    linkurl='/shopping'
                    active={location.pathname === '/shopping'}
                    onClick={handleMenuClick}
                />
                <MenuItem
                    className='nav-item'
                    name='N 플레이스'
                    linkurl='/place'
                    active={location.pathname === '/place'}
                    onClick={handleMenuClick}
                />
            </MenuMenu>
                <MenuMenu position='right'>
                {/* 
                    // Feature 로그인 기능 삭제
                    {isLogin ? <>
                    <MenuItem
                        className='nav-item'
                        name='내 정보'
                        linkurl='/myInfo'
                        active={location.pathname === '/myInfo'}
                        onClick={handleMenuClick}
                    />
                    {isAdmin && <MenuItem
                        className='nav-item'
                        name='관리자 페이지'
                        linkurl='/admin'
                        active={location.pathname === '/admin'}
                        onClick={handleMenuClick}
                    />}
                    <MenuItem
                        className='nav-item'
                        name='로그아웃'
                        active={'' === '로그아웃'}
                        onClick={handleLogoutClick}
                    />
                </> : <MenuItem
                    className='nav-item'
                    name='로그인'
                    linkurl='/login'
                    active={location.pathname === '/login'}
                    onClick={handleMenuClick}
                />}    */}
            </MenuMenu>
        </Menu>
    )
}

export default Header;