import { 
    Input, 
    Button,
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
} from 'semantic-ui-react'
import SearchApi from '../../api/SearchApi';

import { useState } from 'react';

const inputHandlerBuilder = setState => {
    return e => setState(e.target.value);
}

const Search = props => {
    const { api, targetPlaceholder = '상품명' } = props;
    const [keyword, setKeyword] = useState('');
    const [url, setUrl] = useState('');
    const [prodNm, setProdNm] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [rankList, setRankList] = useState([]);

    const handleSearchClick = async e => {
        e.preventDefault();
        if (!keyword || !prodNm) return alert('검색어를 입력하셔야 합니다.')
        setIsLoading(true);
        // const res = await SearchApi.search(keyword, prodNm);
        const res = await api(keyword, prodNm);
        setRankList(res || []);
        setIsLoading(false);
    }

    const handleConsultClick = () => {
        const url = "https://open.kakao.com/o/sP2q7tGg";
        const newWindow = window.open(url, "_blank");

        if (newWindow) {
            newWindow.focus();
        } else {
            alert("팝업 차단 설정이 활성화되어 새 창을 열 수 없습니다.");
        }
    }

    return (
        <div className="search-body">
            <div className="search-bar">
                <Input 
                    className='search-target-name'
                    placeholder="검색어" 
                    value={keyword}
                    onChange={inputHandlerBuilder(setKeyword)}     
                />
                <Input 
                    className='search-target-url'
                    placeholder={targetPlaceholder}
                    value={prodNm}
                    onChange={inputHandlerBuilder(setProdNm)}  
                />
                {isLoading 
                    ? <Button loading primary>Loading</Button>
                    : <Button primary onClick={handleSearchClick}>검색</Button>
                }
                <Button onClick={handleConsultClick}>순위상담</Button>

            </div>
            <div className="search-results">
                <h3>검색결과</h3>
                <div>
                    <Table color="blue" textAlign='center'>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>검색어</TableHeaderCell>
                                <TableHeaderCell>순위</TableHeaderCell>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            { rankList.length > 0 
                                ? <TableRow>
                                    <TableCell>{rankList[0]}</TableCell>
                                    <TableCell>{rankList[2]}</TableCell>
                                </TableRow>
                                : <TableRow>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Search;