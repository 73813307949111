import axios from 'axios';

class Http {
    static get(url) {
        return axios.get(url).then(res => res.data);
    }

    static post(url, data) {
        return axios.post(url, data).then(res => res.data);
    }
}

export default Http;