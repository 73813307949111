import Search from "../../search/Search";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SearchApi from "../../../api/SearchApi";

const Shopping = props => {
    const navigate = useNavigate();

    // Feature 로그인 기능 삭제
    // useEffect(() => {
    //     const userInfo = sessionStorage.getItem('userInfo');
    //     if (!userInfo) {
    //         alert('로그인이 필요한 페이지입니다.');
    //         navigate('/login')
    //     }
    // }, [])

    return (
        <Search api={SearchApi.searchShopping}/>
    )
}

export default Shopping;