import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Pagination
} from 'semantic-ui-react'

import { useState, useEffect } from 'react';
import UserApi from '../../../api/UserApi';
import './admin.css';

const Admin = () => {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        UserApi.getUsers().then(res => {
            setUsers(res.users || []);
        });
    }, [])

    return (
        <div className='admin_body'>
            <h2>회원 목록</h2>
            <div>
                <Table celled padded>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell singleLine>번호</TableHeaderCell>
                            <TableHeaderCell >아이디</TableHeaderCell>
                            <TableHeaderCell>이름</TableHeaderCell>
                            <TableHeaderCell>전화번호</TableHeaderCell>
                            <TableHeaderCell>이메일</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {users.map((user, idx) => (
                            <TableRow key={user.userId}>
                                <TableCell>{idx+1}</TableCell>
                                <TableCell>{user.userId}</TableCell>
                                <TableCell>{user.userNm}</TableCell>
                                <TableCell>{user.userPhone}</TableCell>
                                <TableCell>{user.userEmail}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div>
                {/* <Pagination defaultActivePage={5} totalPages={10} /> */}
            </div>
        </div>
    )
}

export default Admin;