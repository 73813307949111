const Content = props => {

    const handleBannerClick = () => {
        const url = "https://open.kakao.com/o/sP2q7tGg";
        const newWindow = window.open(url, "_blank");

        if (newWindow) {
            newWindow.focus();
        } else {
            alert("팝업 차단 설정이 활성화되어 새 창을 열 수 없습니다.");
        }
    }

    return (
        <div className="contents">
            <div className="left-banner">
                <div className="banner-item banner-link">
                    <img alt="배너입니다." src='/static/img/banner/left.jpg' onClick={handleBannerClick}/>
                </div>
            </div>
            <div className="contents-body">
                {props.children}
                <div className="bottom-banner">
                    <div className="bottom-banner-item banner-link">
                        <img alt="배너입니다." src='/static/img/banner/bottom.jpg' onClick={handleBannerClick} />
                    </div>
                </div>
            </div>
            <div className="right-banner">
                <div className="banner-item banner-link">
                    <img alt="배너입니다." src='/static/img/banner/right.jpg' onClick={handleBannerClick} />
                </div>
            </div>
        </div>
    )
}

export default Content