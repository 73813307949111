import './register.css';

import { useState } from 'react';
import UserApi from '../../../api/UserApi';
import { Button } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';

const Register = props => {

    const navigate = useNavigate();

    const [user, setUsert ] = useState({
        userPw : '',
        userConfirmPw : '',
    });

    const [isValidId, setIsValidId] = useState(false);

    const handleUserData = e => {
        const { name, value } = e.target;
        const newUser = {...user};
        newUser[name] = value;
        setUsert(newUser);
    }

    const handleIdDuplClick = async e => {
        const res = await UserApi.duplCheck(user.userId);
        const msg = res.valid ? '사용 가능한 아이디입니다.' : '사용할 수 없는 아이디입니다.';
        alert(msg);
        setIsValidId(res.valid);
    }

    const handleCheckEmailClick = e => {
        UserApi.emailCheck(user.userEmail).then(res => {
            if (res.code === 'success') return;
            alert('이메일 전송에 실패하였습니다. 잠시후 다시 시도해주세요.')
        });
        alert('메일 전송에 1~3분 정도 소요됩니다.');
    }

    const beforeRegisterCheck = () => {
        if (!isValidId) return false;
        if (!user.userId || !user.userNm
            || !user.userPw || !user.userConfirmPw 
            || !user.userEmail || !user.userEmailValid 
            || !user.userPhone) return false;
        if (user.userPw !== user.userConfirmPw) return false;
        return true;
    }

    const handleRegisterClick = async e => {
        e.preventDefault();
        const res = await UserApi.join(user);
        if (res && res.complete === true) {
            alert('회원가입을 축하합니다.');
            navigate('/login')
        } else {
            alert('회원가입에 실패하였습니다. 입력하신 정보나 이메일 인증 번호를 다시 확인해주세요.');
        }
    }

    return (
        <div className="form-container">
            <form>
                <div className="form-group" target="pw">
                    <label for="userId" className="my-info-lb"><span className="required">*</span>아이디 :</label>
                    <div className="input-wrapper">
                        <input type="text" name="userId" className="input-field" placeholder="아이디" required value={user.userId} onChange={handleUserData} />
                        {/* <button type="button" className="action-button" onClick={handleIdDuplClick}>중복확인</button> */}
                        <Button primary onClick={handleIdDuplClick}>중복확인</Button>
                    </div>
                </div>
                <div className="form-group password-group">
                    <label for="userPw" className="my-info-lb"><span className="required">*</span>비밀번호 :</label>
                    <div className="input-wrapper">
                        <input type="password" name="userPw" className="input-field" placeholder="비밀번호" required value={user.userPw} onChange={handleUserData} />
                    </div>
                    <div className="input-wrapper">
                        <input type="password" name="userConfirmPw" className="input-field" placeholder="비밀번호 확인" required value={user.userConfirmPw} onChange={handleUserData} />
                    </div>
                    <div className="cfm-ps-info">
                        {user.userPw.length < 8 && <span className="warning">비밀번호는 8자리 이상 입력하세요.</span>}
                        {(user.userPw.length < 8 && user.userPw !== user.userConfirmPw) && <br/>}
                        {user.userPw !== user.userConfirmPw && <span className="warning">비밀번호가 일치하지 않습니다.</span>}
                    </div>
                </div>
                <div className="form-group" target="id">
                    <label for="userNm" className="my-info-lb"><span className="required">*</span>이름 :</label>
                    <div className="input-wrapper">
                        <input type="text" name="userNm" id="userNm" className="input-field" placeholder="이름" value={user.userNm} onChange={handleUserData} />
                    </div>
                </div>
                <div className="form-group password-group" target="pw">
                    <label for="userEmail" className="my-info-lb"><span className="required">*</span>이메일 :</label>
                    <div className="input-wrapper">
                        <input type="email" name="userEmail" className="input-field" placeholder="korea@naver.com" value={user.userEmail} onChange={handleUserData} />
                        <Button primary onClick={handleCheckEmailClick}>인증하기</Button>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" name="userEmailValid" className="input-field" placeholder="인증번호입력" value={user.userEmailValid} onChange={handleUserData} />
                    </div>
                </div>
                <div className="form-group" target="id">
                    <label for="userPhone" className="my-info-lb"><span className="required">*</span>휴대전화번호 :</label>
                    <div className="input-wrapper">
                        <input type="tel" name="userPhone" id="userPhone" className="input-field" placeholder="01012345678 (-제외)" required value={user.userPhone} onChange={handleUserData} />
                    </div>
                    {/* <div className="ph-info">
                        <span className="warning">입력하신 정보는 전화번호 양식이 아닙니다.</span>
                    </div> */}
                </div>
            </form>
            <div>
                { beforeRegisterCheck() 
                    ? <Button onClick={handleRegisterClick} fluid primary >가입하기</Button>
                    : <Button fluid disabled>가입하기</Button>
                }
            </div>
        </div>
    )
}

export default Register;