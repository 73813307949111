import './login.css';

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Http from '../../../util/Http';

const Login = props => {
    const navigate = useNavigate();
    const [loginId, setLoginId] = useState('');
    const [loginPw, setLoginPw] = useState('');

    const handleIdInput = e => setLoginId(e.target.value);
    const handlePwInput = e => setLoginPw(e.target.value); 

    const handleLoginClick = async () => {
        const res = await Http.post('/user/login', {username : loginId, password: loginPw});
        if(res.userInfo) {
            const customEvent = new CustomEvent('userInfo', {
                detail: { userInfo: res.userInfo }
            });
            document.dispatchEvent(customEvent);
            navigate('/')
        }
    }

    const handleKeyUpInput = e => {
        e.preventDefault();
        if (e.which !== 13) return;
        handleLoginClick();
    }

    return (
        <div className="contents">
            <div class="login-container">
                <div class="login-box">
                    <h2>로그인</h2>
                    <div class="input-group">
                        <input type="text" placeholder="아이디" value={loginId} onChange={handleIdInput} />
                    </div>
                    <div class="input-group">
                        <input type="password" placeholder="비밀번호" value={loginPw} onChange={handlePwInput} onKeyUp={handleKeyUpInput}/>
                    </div>
                    <div class="button-group">
                        <button onClick={handleLoginClick}>로그인</button>
                    </div>
                    <div class="links">
                        <Link to="/register">회원가입</Link>
                        <Link to="/findInfo">아이디/비밀번호 찾기</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;